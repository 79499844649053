import Image from 'next/legacy/image';
import { Container, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TrackButton } from 'components/TrackButton';
import { TrackingEvent } from 'lib/tracking/events';

import type { AssetDetails } from 'contentful';

const ColoredBackground = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  marginTop: '150px',
}));

const ImageWithCTAWrapper = styled('div')(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  display: 'flex',
  padding: `${theme.spacing(4)} 15% ${theme.spacing(4)} ${theme.spacing(0)}`,
  [theme.breakpoints.down('md')]: {
    padding: 0,
    flexDirection: 'column',
  },
}));

const ImageWrapper = styled('div')(({ theme }) => ({
  width: '338px',
  flex: '0 0 auto',
  margin: '-100px auto -30px',
  bottom: 0,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  [theme.breakpoints.down('lg')]: {
    margin: '-50px auto -30px',
  },
}));

const TextWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  marginLeft: '80px',
  marginTop: 0,
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
    marginTop: '80px',
  },
}));

const Headline = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
`;

const Paragraph = styled(Typography)`
  font-size: 14px;
  margin: 10px 0;
`;

const TextButton = styled(TrackButton)`
  min-height: 48px;
  margin: 10px 0 0 0;
  padding: 12px 60px;

  ${(props) => props.theme.breakpoints.down('md')} {
    width: 100%;
    margin: 10px 0 48px 0;
  }
`;

export type ImageCtaProps = any;
export const ImageWithCTA = (data: ImageCtaProps) => {
  const imageFile = data.image?.fields.file;
  const plainImgUrl = imageFile!.url as string;
  const imgUrl = plainImgUrl?.startsWith('//')
    ? `https:${plainImgUrl}`
    : plainImgUrl;

  const imageDetails = imageFile!.details as AssetDetails;

  return (
    <ColoredBackground>
      <Container>
        <ImageWithCTAWrapper>
          <ImageWrapper>
            <Image
              src={imgUrl}
              blurDataURL={imgUrl}
              height={imageDetails.image?.height}
              width={imageDetails.image?.width}
              layout="responsive"
              alt={
                (data.image?.fields.description as string) ||
                (imageFile!.fileName as string)
              }
            />
          </ImageWrapper>
          <TextWrapper>
            <Headline variant="h3">{data.headline}</Headline>
            <Paragraph>{data.text}</Paragraph>
            <div>
              <Link href={data.ctaUrl}>
                <TextButton
                  trackingEvent={TrackingEvent.IMAGE_BUTTON_ONINTERACT}
                  variant="contained"
                >
                  {data.ctaText}
                </TextButton>
              </Link>
            </div>
          </TextWrapper>
        </ImageWithCTAWrapper>
      </Container>
    </ColoredBackground>
  );
};
